const FRESHDESK_WEB_TOKEN = window.__FRESHDESK_WEB_TOKEN__;
const FRESHDESK_SITE_ID = window.__FRESHDESK_SITE_ID__;

if (
  FRESHDESK_WEB_TOKEN &&
  FRESHDESK_WEB_TOKEN.indexOf("FRESHDESK_WEB_TOKEN") === -1 &&
  FRESHDESK_SITE_ID &&
  FRESHDESK_SITE_ID.indexOf("FRESHDESK_SITE_ID") === -1 &&
  window.location.pathname !== "/vms"
) {
  function initFreshChat() {
    window.fcWidget.init({
      token: FRESHDESK_WEB_TOKEN,
      host: "https://wchat.eu.freshchat.com",
      siteId: FRESHDESK_SITE_ID,
    });
  }
  function initialize(d, id) {
    if (d.getElementById(id)) {
      initFreshChat();
    } else {
      const a = d.getElementsByTagName("head")[0];
      const e = d.createElement("script");
      e.id = id;
      e.async = !0;
      e.src = "https://wchat.eu.freshchat.com/js/widget.js";
      e.onload = initFreshChat;
      a.appendChild(e);
    }
  }
  (function initiateCall() {
    initialize(document, "freshchat-js-sdk");
  })();
}
